<template>
  <section>
    <router-view/>
  </section>
</template>

<script>
export default {
  name: 'ContactIndex'
}
</script>

<style lang="scss" scoped>

</style>
